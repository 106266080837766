import React from 'react'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {motion, useTransform} from 'framer-motion'
import {color, ColorPresence, LangOpt, scale} from 'core/theme'

const StyledNav = styled(motion.nav)`
  ul {
    flex-direction: row;
    margin: 0 ${rem(-scale[2])};
  }

  li {
    margin: 0 ${rem(scale[2])};
  }

  a {
    color: inherit;
    margin: -0.5em;
    padding: 0.5em;
    text-decoration: none;

    &.active {
      position: relative;

      &::after {
        background-color: ${color.brand.yellow};
        border-radius: ${rem(4)};
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: -0.25em;
        left: 0;
        height: ${rem(4)};
      }
    }
  }
`

const StyledLang = styled(motion.nav)`
  border: 0px solid ${color.white};
  color: white;
  margin: 0 1.125rem;
  width: 100%;
  background: transparent;
  background-color: transparent;
  vertical-align: top;

  &:focus {
    outline: 0;
    border-color: ${color.white};
  }

  text-align: center;
  text-align-last: center;

  option {
    text-align: center;
    text-align-last: center;
    vertical-align: text-top;
    font: 10px;
    background-color: transparent;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
`

const StyledSelect = styled.select`
  border: 0px solid ${color.white};
  color: inherit;
  width: 100%;
  background: transparent;
  background-color: transparent;
  font-family: 'Noto Sans',sans-serif;
  font-size: 1.125rem;
  vertical-align: top;
    
  &:focus {
    outline: 0;
    border-color: ${color.white};
  }

  text-align: center;
  text-align-last: center;

  option {
    text-align: center;
    text-align-last: center;
    font: 10px;
    background-color: rgb(85,177,208);
    color: ${color.white}
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  
`

export default function MainNav({pages}) {
  const {color, saturation} = React.useContext(ColorPresence)
  const textColor = useTransform(saturation, [0, 1], ['#31589F', '#ffffff'])
  const langOpt = React.useContext(LangOpt)

  //console.log(langOpt.language)

  function showTitle(page) {
    //console.log(langOpt.language);
    if(langOpt.language === "zh") {
      return page.titleZh
    } else  {
      return page.title
    }
  }

  function resetColors() {
    color.set('#ffffff')
    saturation.set(0)
  }



  function showNavLink(page) {

      if(page.linkList != null) {
          return (
              <StyledLang style={{color: textColor}}>
                  <StyledSelect required
                                value=""
                                onChange={
                                    event => {
                                        //console.log(event.target.value)
                                        window.location = event.target.value;
                                    }
                                }>
                      <option value="" disabled hidden>{showTitle(page)}</option>
                      {page.linkList.map((option) => (
                          <option value={option.value}>{option.label}</option>
                      ))}
                  </StyledSelect>
              </StyledLang>
          )
      } else if(page.link != null) {
          return (
              <a href={`${page.link}`}>{showTitle(page)}</a>
          )
      } else {
          return (
              <NavLink to={`/${page.slug}`} onClick={() => resetColors()}>
                {showTitle(page)}
              </NavLink>
          )
      }
  }

  return (
    <StyledNav style={{color: textColor}}>
      <ul>
        {pages.map(page => (
          <li key={page.slug}>
            {showNavLink(page)}
          </li>
        ))}
      </ul>
    </StyledNav>
  )
}
