import React from 'react'
import {Link,useHistory, useLocation} from 'react-router-dom'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {
    AnimatePresence,
    motion,
    useSpring,
    useViewportScroll,
} from 'framer-motion'
import {useMatchMedia} from 'core/hooks'
import {ColorPresence, breakpoints, media, scale} from 'core/theme'
import {ContentWidth} from 'elements'
import Logo from './components/Logo'
import MainNav from './components/MainNav'
import MobileNav from './components/MobileNav'
import MobileToggle from './components/MobileToggle'
import LanguageToggle from "./components/LanguageToggle";


const pages = [
    {
        slug: 'about-us',
        title: 'About Us',
        titleZh: '關於Food Union',
    },
    {
        slug: 'news',
        title: 'News',
        titleZh: 'News',
    },
    {
        slug: 'brands',
        title: 'Brands',
        titleZh: '品牌',
    },
    {
        slug: 'quality',
        title: 'Quality',
        titleZh: '產品質素',
    },
    {
        slug: 'contacts',
        title: 'Contacts',
        titleZh: '聯絡我們',
    },
    {
        slug: 'shop-now',
        title: 'E-catalogue',
        titleZh: '電子型錄',
        link: 'https://foodunionasia.com/fu/static/pdf/FoodUnionCatalogue.pdf'
    }

        // linkList: [
        //     {
        //         label: "Singapore",
        //         value: "https://www.tuckshop.sg/collections/dairy",
        //     },
        //     {
        //         label: "Hong Kong",
        //         value: "https://ekselence-hk.com/",
        //     }]
        //link: 'https://www.foodunion-hk.com/'
    // {
    //     slug: 'babyriki',
    //     title: 'BabyRiki Video',
    //     titleZh: 'BabyRiki劇集',
    //     link: 'https://www.foodunionasia.com/babyriki'
    // },

]

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  min-height: ${rem(scale[6])};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  ${media.tablet`
    min-height: ${rem(scale[9])};
  `};
`

const BackgroundColor = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${rem(-scale[1])};
`

export const Column = styled.div`
  margin: 0 ${rem(scale[1])};
`

const HomeLink = styled(Link)`
  box-sizing: content-box;
  display: block;
  width: ${rem(72)};

  ${media.tablet`
    width: ${rem(82)};
  `};

  svg {
    width: 100%;
  }
`

const Pusher = styled.div`
  padding-top: ${rem(59)};

  ${media.tablet`
    padding-top: ${rem(81)};
  `};
`




export default function NavBar() {

  useLocation()
  useHistory()

  //const langOpt = React.useContext(LangOpt)
  const {color, saturation} = React.useContext(ColorPresence)


  const prevPresenceRef = React.useRef()

  const matchLaptop = useMatchMedia(breakpoints.laptop)
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false)

  const {scrollY} = useViewportScroll()
  const backgroundOpacity = useSpring(0, {
    damping: 40,
    stiffness: 150,
  })
  const backgroundOpacityTarget = React.useRef(0)


  React.useEffect(
    () =>
      scrollY.onChange(latest => {
        if (latest > 100 && backgroundOpacityTarget.current !== 1) {
          backgroundOpacityTarget.current = 1
          backgroundOpacity.set(1)
        } else if (latest <= 100 && backgroundOpacityTarget.current !== 0) {
          backgroundOpacityTarget.current = 0
          backgroundOpacity.set(0)
        }
      }),
    [scrollY, backgroundOpacity]
  )

  React.useEffect(() => {
    document.body.classList.toggle('disable-scroll', mobileNavOpen)
  }, [mobileNavOpen])

  return (
    <>
      <StyledHeader>
        <BackgroundColor
          style={{
            backgroundColor: color,
            opacity: backgroundOpacity,
          }}
        />

        <ContentWidth>
          <Flex>
            <Column>
              <HomeLink
                to='/'
                title='Go to the homepage'
                onClick={() => {
                  backgroundOpacityTarget.current = 0
                  backgroundOpacity.set(0)
                  setMobileNavOpen(false)
                }}
              >
                <Logo />
              </HomeLink>
            </Column>

            <Column>
              {matchLaptop ? (
                <MainNav pages={pages} />
              ) : (
                <MobileToggle
                  isOpen={mobileNavOpen}
                  onToggle={() => {
                    if (mobileNavOpen) {
                      const {
                        colorValue,
                        saturationValue,
                      } = prevPresenceRef.current
                      color.set(colorValue)
                      saturation.set(saturationValue)
                    } else {
                      prevPresenceRef.current = {
                        colorValue: color.get(),
                        saturationValue: saturation.get(),
                      }
                      color.set('#FFFFFF')
                      saturation.set(0)
                    }
                    backgroundOpacityTarget.current = mobileNavOpen ? 1 : 0
                    backgroundOpacity.set(backgroundOpacityTarget.current)
                    setMobileNavOpen(!mobileNavOpen)
                  }}
                />
              )}
            </Column>
              <Column>
                  <LanguageToggle />
              </Column>
          </Flex>
        </ContentWidth>
      </StyledHeader>

      <AnimatePresence initial={false}>
        {mobileNavOpen && (
          <MobileNav
            color={color}
            pages={pages}
            onNavigate={() => setMobileNavOpen(false)}
          />
        )}
      </AnimatePresence>

      <Pusher />
    </>
  )
}
