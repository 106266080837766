import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {motion, useTransform} from 'framer-motion'
import {ColorPresence, color, scale, LangOpt} from 'core/theme'


const StyledLang = styled(motion.nav)`
  border: 0px solid ${color.white};
  color: white;
  font: 12px;
  padding: ${rem(scale[1])};
  width: 100%;
  background: transparent;
  background-color: transparent;

  &:focus {
    outline: 0;
    border-color: ${color.white};
  }

  text-align: center;
  text-align-last: center;

  option {
    text-align: center;
    text-align-last: center;
    font: 10px;
    background-color: transparent;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
`

const StyledSelect = styled.select`
  border: 0px solid ${color.white};
  color: inherit;
  font: 12px;
  padding: ${rem(scale[1])};
  width: 100%;
  background: transparent;
  background-color: transparent;

  &:focus {
    outline: 0;
    border-color: ${color.white};
  }

  text-align: center;
  text-align-last: center;

  option {
    text-align: center;
    text-align-last: center;
    font: 10px;
    background-color: transparent;
    color: ${color.black}
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  
`



export default function LanguageToggle() {
    const {saturation} = React.useContext(ColorPresence)
    const textColor = useTransform(saturation, [0, 1], ['#31589F', '#ffffff'])

    const location = useLocation()
    const history = useHistory()

    const langOpt = React.useContext(LangOpt)

    function setLanguage(language) {
        langOpt.language = language;
        history.push(location.pathname)

    }

    return (
        <StyledLang style={{color: textColor}}>
            <StyledSelect required
                          value=""
                          onChange={
                              event => {
                                  setLanguage(event.target.value)

                                  //console.log(event.target.value)
                              }
                          }>
                    <option value="" disabled hidden>EN/中文</option>
                    <option value='en'>EN</option>
                    <option value='zh'>中文</option>
            </StyledSelect>
        </StyledLang>
    )
}