import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {NavLink} from 'react-router-dom'
import {motion, useTransform} from 'framer-motion'
import {color, LangOpt} from 'core/theme'

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 9;

  display: flex;
  align-items: center;
  justify-content: center;
`

const Background = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`

const List = styled(motion.ul)`
  justify-content: center;
`

const Item = styled(motion.li)`
  font-size: ${rem(34)};
  font-weight: 700;

  > a {
    color: inherit;
    text-decoration: none;

    &.active {
      color: ${color.text.green};
    }
  }
`

const StyledLang = styled(motion.nav)`
  border: 0px solid ${color.white};
  margin: 0 0.125rem;
  width: 100%;
  background: transparent;
  background-color: transparent;
  vertical-align: top;

  &:focus {
    outline: 0;
    border-color: ${color.white};
  }

  text-align: inherit;
  text-align-last: inherit;

  option {
    text-align: inherit;
    text-align-last: inherit;
    vertical-align: text-top;
    font: 10px;
    background-color: transparent;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
`

const StyledSelect = styled.select`
  border: 0px solid ${color.white};
  color: inherit;
  width: 100%;
  background: transparent;
  background-color: transparent;
  font-family: 'Noto Sans',sans-serif;
  font-size: 2.125rem;
  font-weight: 700;
  vertical-align: top;
    
  &:focus {
    outline: 0;
    border-color: ${color.white};
  }

  text-align: inherit;
  text-align-last: inherit;
  padding: 0.5em;

  option {
    text-align: inherit;
    text-align-last: inherit;
    font: 10px;
    background-color: transparent;
    color: ${color.black}
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  
`

const backgroundVariants = {
  open: ({height = 1000}) => ({
    backgroundColor: color.white,
    clipPath: `circle(${height * 2 + 200}px at calc(100% - 30px) 30px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: ({color}) => ({
    backgroundColor: color,
    clipPath: 'circle(0px at calc(100% - 30px) 30px)',
    transition: {
      delay: 0.2,
      type: 'spring',
      stiffness: 150,
      damping: 40,
    },
  }),
  exit: ({color}) => ({
    backgroundColor: color,
    clipPath: 'circle(0px at calc(100% - 30px) 30px)',
    transition: {
      delay: 0.2,
      type: 'spring',
      stiffness: 150,
      damping: 40,
    },
  }),
}

const listVariants = {
  open: {
    transition: {staggerChildren: 0.05, delayChildren: 0.2},
  },
  closed: {
    transition: {staggerChildren: 0.03, staggerDirection: -1},
  },
}

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: {type: 'spring', damping: 15, stiffness: 150},
    },
  },
  closed: {
    y: 75,
    opacity: 0,
    transition: {
      y: {type: 'spring', damping: 15, stiffness: 150},
    },
  },
}

export default function MobileNav({color, pages, onNavigate}) {
  const [height, setHeight] = React.useState(0)
  const langOpt = React.useContext(LangOpt)

  const measuredRef = React.useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
    }
  }, [])

  function showTitle(page) {
    //console.log(langOpt.language);
    if(langOpt.language === "zh") {
      return page.titleZh
    } else  {
      return page.title
    }
  }

  function showNavLink(page) {
    if(page.linkList != null) {
      return (
          <StyledLang>
            <StyledSelect required
                          value=""
                          onChange={
                            event => {
                              //console.log(event.target.value)
                              window.location = event.target.value;
                            }
                          }>
              <option value="" disabled hidden>{showTitle(page)}</option>
              {page.linkList.map((option) => (
                  <option value={option.value}>{option.label}</option>
              ))}
            </StyledSelect>
          </StyledLang>
      )
    } else if(page.link != null) {
      return (
          <a href={`${page.link}`}>{showTitle(page)}</a>
      )
    } else {
      return (
          <NavLink to={`/${page.slug}`} onClick={onNavigate}>
            {showTitle(page)}
          </NavLink>
      )
    }
  }

  return (
    <Container ref={measuredRef}>
      {height > 0 ? (
        <>
          <Background
            initial='closed'
            animate='open'
            exit='exit'
            custom={{height, color: color.get()}}
            variants={backgroundVariants}
          />

          <nav>
            <List
              initial='closed'
              animate='open'
              exit='closed'
              variants={listVariants}
            >
              {pages.map(page => (
                <Item key={page.slug} variants={itemVariants}>
                  {showNavLink(page)}
                </Item>
              ))}
            </List>
          </nav>
        </>
      ) : null}
    </Container>
  )
}
