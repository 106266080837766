import React from 'react'


export const LangOpt = React.createContext({
        language: "en"
})

export function LangOptProvider({children}) {
    const language = "en"

    return (
        <LangOpt.Provider value={{language}}>
            {children}
        </LangOpt.Provider>
    )
}
