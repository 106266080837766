import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

if (typeof window.IntersectionObserver === 'undefined') {
  import('intersection-observer')
}

if (typeof window.AbortController === 'undefined') {
  import('abortcontroller-polyfill')
}

/*
const initialGlobalState = {
  language: "EN",
  bool: true
};
const GlobalStateContext = React.createContext(initialGlobalState);
const DispatchStateContext = React.createContext(undefined);
*/

/**
 * Global State provider & hooks
 */
/*
export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
      (state, newValue) => ({ ...state, ...newValue }),
      initialGlobalState
  );
  return (
      <GlobalStateContext.Provider value={state}>
        <DispatchStateContext.Provider value={dispatch}>
          {children}
        </DispatchStateContext.Provider>
      </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => [
  React.useContext(GlobalStateContext),
  React.useContext(DispatchStateContext)
];
*/


ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
