import React from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {AnimatePresence} from 'framer-motion'
import {ThemeProvider, ColorPresenceProvider} from 'core/theme'
import {Loader, NavBar, FooterCompact, CookieConsent} from 'modules'
// import ScrollRestore from 'pages/components/ScrollRestore'
import ScrollToTop from 'pages/components/ScrollToTop'

const Home = React.lazy(() => import('pages/Home'))
const AboutUs = React.lazy(() => import('pages/AboutUs'))
const News = React.lazy(() => import('pages/News'))
const NewsPost = React.lazy(() => import('pages/NewsPost'))
const Brands = React.lazy(() => import('pages/Brands'))
const BrandsPost = React.lazy(() => import('pages/BrandsPost'))
const Companies = React.lazy(() => import('pages/Companies'))
const CompaniesPost = React.lazy(() => import('pages/CompaniesPost'))
const Quality = React.lazy(() => import('pages/Quality'))
const Trendtrack = React.lazy(() => import('pages/Trendtrack'))
const Contacts = React.lazy(() => import('pages/Contacts'))
const ContactsCountry = React.lazy(() => import('pages/ContactsCountry'))
const ShopNow = React.lazy(() => import('pages/ShopNow'))
const MediaKit = React.lazy(() => import('pages/MediaKit'))
const PrivacyPolicy = React.lazy(() => import('pages/PrivacyPolicy'))
const NotFound = React.lazy(() => import('pages/NotFound'))

function App() {
  return (
    <ThemeProvider>
      <Router>
        {/* FIXME: Problems with content layout and
            section offset, background color presence.
          <ScrollRestore />
        */}

        <Helmet
          defaultTitle='Food Union Asia Official Website'
          titleTemplate='%s | Food Union Asia Official Website'
        >
          <meta
            name='description'
            content='Pioneering the dairy industry since 1909'
          />
        </Helmet>

        <ColorPresenceProvider>
          <NavBar />

          <React.Suspense fallback={<Loader />}>
            <Route
              render={({location}) => (
                <>
                  <ScrollToTop />

                  <AnimatePresence
                    exitBeforeEnter
                    // onExitComplete={() => {
                    //   window.scrollTo(0, 0)
                    // }}
                  >
                    <Switch location={location} key={location.pathname}>
                      <Route exact path='/' component={Home} />
                      <Route path='/about-us' component={AboutUs} />
                      <Route path='/news/:slug' component={NewsPost} />
                      <Route path='/news' component={News} />
                      <Route path='/brands/:slug' component={BrandsPost} />
                      <Route path='/brands' component={Brands} />
                      <Route
                        path='/companies/:slug'
                        component={CompaniesPost}
                      />
                      <Route path='/companies' component={Companies} />
                      <Route path='/quality' component={Quality} />
                      <Route
                        path='/trendtrack'
                        component={Trendtrack}
                      />
                      <Route
                        path='/contacts/:country'
                        component={ContactsCountry}
                      />
                      <Route path='/shop-now' component={ShopNow} />
                      <Route path='/contacts' component={Contacts} />
                      <Route path='/media-kit' component={MediaKit} />
                      <Route path='/privacy-policy' component={PrivacyPolicy} />
                      <Route component={NotFound} />
                    </Switch>
                  </AnimatePresence>
                </>
              )}
            />
          </React.Suspense>

          <FooterCompact />
          <CookieConsent />
        </ColorPresenceProvider>
      </Router>
    </ThemeProvider>
  )
}

export default App
